import React from 'react';
import { graphql } from 'gatsby';
import Layout3 from '../components/layout2';

import Sdgs from '../containers/sdgs';

import Styles from './chirisogo.module.css';


const HomePage = (props: any) => {
  const { data } = props;

  return (
    <Layout3>
  <div className={Styles.book}>
  <div className={Styles.bookInbox}>
  <ul>
    <li><a href="">テキストリンク</a>←リンクも使えます。</li>
    <li>この本はCSSで作成されています。</li>
    <li>カーソルがホバーしている間は開いたままです。</li>
  </ul>
  </div>
  </div>
      <Sdgs />
    </Layout3>
  );
};

export default HomePage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
